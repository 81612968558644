
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ConsoleFilter, ConsoleFilterType } from '@/store/console/types'

@Component({})
export default class ConsoleFilterDialog extends Vue {
  @Prop({ type: Boolean, required: true })
  public value!: boolean

  @Prop({ type: Object, required: true })
  public rules!: any

  @Prop({ type: Object, required: true })
  public filter!: ConsoleFilter

  valid = true

  types = [
    {
      text: this.$t('app.setting.label.contains'),
      value: ConsoleFilterType.Contains,
      rules: [this.rules.required]
    },
    {
      text: this.$t('app.setting.label.starts_with'),
      value: ConsoleFilterType.StartsWith,
      rules: [this.rules.required]
    },
    {
      text: this.$t('app.setting.label.expression'),
      value: ConsoleFilterType.Expression,
      rules: [this.rules.required, this.rules.validExpression]
    }
  ]

  get type () {
    return this.types.find(f => f.value === this.filter?.type) || this.types[0]
  }

  handleSave () {
    if (this.valid) {
      this.$emit('save', this.filter)
      this.$emit('input', false)
    }
  }
}
