
import { Component, Vue } from 'vue-property-decorator'
import { appInit } from '@/init'
import consola from 'consola'
import { InitConfig } from '@/store/config/types'

@Component({})
export default class Login extends Vue {
  username = ''
  password = ''
  valid = true
  error = false
  loading = false
  source = 'moonraker'
  availableSources = [this.source]

  async mounted () {
    const authInfo = await this.$store.dispatch('auth/getAuthInfo')
    this.source = authInfo.defaultSource ?? this.source
    this.availableSources = authInfo.availableSources ?? this.availableSources
  }

  async handleLogin () {
    this.error = false
    this.loading = true
    try {
      await this.$store.dispatch('auth/login', { username: this.username, password: this.password, source: this.source })
    } catch (err) {
      this.error = true
    }
    this.loading = false

    // Re-init the app.
    if (!this.error) {
      const instance = this.$store.getters['config/getCurrentInstance']
      appInit(instance, this.$store.state.config.hostConfig)
        .then((config: InitConfig) => {
          // Reconnect the socket with the new instance url.
          if (config.apiConnected && config.apiAuthenticated) {
            consola.debug('Activating socket with config', config)
            this.$socket.connect(config.apiConfig.socketUrl)
          }
        })
    }
  }
}
