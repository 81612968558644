
import { Component, Mixins } from 'vue-property-decorator'
import { appInit } from '@/init'
import StateMixin from '@/mixins/state'
import { InitConfig, InstanceConfig } from '@/store/config/types'

@Component({
  components: {}
})
export default class SocketDisconnected extends Mixins(StateMixin) {
  reload () {
    window.location.reload()
  }

  get instances (): InstanceConfig[] {
    return this.$store.getters['config/getInstances']
  }

  get activeInstance () {
    return this.instances.find(instance => instance.active)
  }

  reconnect (instance: InstanceConfig) {
    // Re-init the app.
    appInit(instance, this.$store.state.config.hostConfig)
      .then((config: InitConfig) => {
        // Reconnect the socket with the instance url.
        if (config.apiConfig.socketUrl && config.apiConnected && config.apiAuthenticated) {
          this.$socket.connect(config.apiConfig.socketUrl)
        }
      })
  }
}
