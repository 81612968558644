var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-subheader',{attrs:{"id":"camera"}},[_vm._v(" "+_vm._s(_vm.$tc('app.setting.title.camera', 2))+" ")]),_c('v-card',{staticClass:"mb-4",attrs:{"elevation":5,"dense":""}},[_c('app-setting',[_c('app-btn',{attrs:{"disabled":_vm.cameras.length >= 3,"outlined":"","small":"","color":"primary"},on:{"click":_vm.handleAddDialog}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" $plus ")]),_vm._v(" "+_vm._s(_vm.$t('app.setting.btn.add_camera'))+" ")],1)],1),_c('v-divider'),_vm._l((_vm.cameras),function(camera,i){return [_c('app-setting',{key:camera.id,attrs:{"r-cols":2},on:{"click":function($event){return _vm.handleEditDialog(camera)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(camera.name)+" "),(!camera.enabled)?_c('v-icon',{attrs:{"right":"","small":"","color":"warning"}},[_vm._v(" $warning ")]):_vm._e()]},proxy:true}],null,true)},[_c('app-btn',{attrs:{"fab":"","text":"","x-small":"","color":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleRemoveCamera(camera)}}},[_c('v-icon',{attrs:{"color":""}},[_vm._v(" $close ")])],1)],1),(i < _vm.cameras.length - 1 && _vm.cameras.length > 0)?_c('v-divider',{key:camera.id + '_divider'}):_vm._e()]}),_c('v-divider'),_c('app-setting',{attrs:{"title":_vm.$t('app.setting.label.camera_fullscreen_action.title')}},[_c('v-select',{attrs:{"filled":"","dense":"","hide-details":"","items":[
            {
              text: _vm.$t('app.setting.label.camera_fullscreen_action.embed'),
              value: 'embed',
            },{
              text: _vm.$t('app.setting.label.camera_fullscreen_action.rawstream'),
              value: 'rawstream',
            }
          ]},model:{value:(_vm.defaultFullscreenAction),callback:function ($$v) {_vm.defaultFullscreenAction=$$v},expression:"defaultFullscreenAction"}})],1),(_vm.dialogState.camera)?_c('camera-config-dialog',{attrs:{"camera":_vm.dialogState.camera},on:{"save":_vm.handleSaveCamera},model:{value:(_vm.dialogState.active),callback:function ($$v) {_vm.$set(_vm.dialogState, "active", $$v)},expression:"dialogState.active"}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }