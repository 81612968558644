
import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { Waits } from '@/globals'

@Component({
  components: {}
})
export default class ExtruderSelection extends Mixins(StateMixin) {
  get extruders () {
    return this.$store.getters['printer/getExtruders']
  }

  get extruder () {
    return this.$store.state.printer.printer.toolhead.extruder
  }

  set extruder (extruder: string) {
    this.sendGcode(`ACTIVATE_EXTRUDER EXTRUDER=${extruder}`, Waits.onExtruderChange)
  }
}
