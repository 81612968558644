
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class AppSaveConfigAndRestartBtn extends Vue {
  @Prop({ type: Boolean, default: false })
  public disabled!: boolean

  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  get isExpanded () {
    return this.$vuetify.breakpoint.mdAndUp
  }
}
