
import { Component, Prop, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { FilePreviewState } from '@/store/files/types'

@Component({})
export default class FilePreviewDialog extends Mixins(StateMixin) {
  @Prop({ type: Boolean, default: false })
  public value!: boolean

  @Prop({ type: Object })
  public file?: FilePreviewState

  @Prop({ type: Boolean, default: false })
  public downloadable!: boolean

  @Prop({ type: Boolean, default: false })
  public removable!: boolean

  get icon () {
    if (this.isVideo) {
      return 'video'
    } else {
      return 'file'
    }
  }

  get width () {
    return window.innerWidth * (this.$vuetify.breakpoint.mdAndDown ? 1 : 0.5)
  }

  get isVideo () {
    return this.file?.type.startsWith('video/')
  }
}
