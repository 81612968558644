
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import {
  AppFileWithMeta,
  FileBrowserEntry,
  FileFilter
} from '@/store/files/types'
import { AppTableHeader } from '@/types'
import FilesMixin from '@/mixins/files'

import FileRowItem from './FileRowItem.vue'

@Component({
  components: {
    FileRowItem
  }
})
export default class FileSystemBrowser extends Mixins(FilesMixin) {
  @Prop({ type: String, required: true })
  public root!: string

  @Prop({ type: Array, required: true })
  public files!: FileBrowserEntry[]

  @Prop({ type: Boolean, default: false })
  public dense!: boolean

  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  // Currently defined list of headers.
  @Prop({ type: Array, required: true })
  public headers!: AppTableHeader[]

  @Prop({ type: String, required: false })
  public search!: string

  @Prop({ type: Array, default: () => { return [] } })
  public filters!: FileFilter[]

  @Prop({ type: Boolean, required: true })
  public dragState!: boolean

  @Prop({ type: Boolean, default: false })
  public disabled!: boolean

  @Prop({ type: Boolean, default: false })
  public bulkActions!: boolean

  @Prop({ type: Boolean, default: false })
  public largeThumbnails!: boolean

  @Prop({ type: Array, required: true })
  public selected!: (FileBrowserEntry | AppFileWithMeta)[]

  dragItem: FileBrowserEntry | AppFileWithMeta | null = null
  ghost: HTMLDivElement | undefined = undefined
  selectedItems: (FileBrowserEntry | AppFileWithMeta)[] = []

  // Is the history component enabled
  get showHistory () {
    return (
      this.$store.getters['server/componentSupport']('history') &&
      this.root === 'gcodes'
    )
  }

  mounted () {
    this.selectedItems = this.selected
  }

  // Make sure we update the selected items if it's changed.
  @Watch('selected')
  onSelected (selected: (FileBrowserEntry | AppFileWithMeta)[]) {
    this.selectedItems = selected
  }

  // When the selected items change, update the parent.
  handleSelected (selected: (FileBrowserEntry | AppFileWithMeta)[]) {
    this.$emit('update:selected', selected)
  }

  // We ignore our [..] dir, so handle faking our checkbox states.
  handleItemSelected (item: { item: FileBrowserEntry | AppFileWithMeta; value: boolean }) {
    // If last two, and filtered results in 0 - set to 0.
    if (
      !item.value &&
      this.selectedItems.length <= 2 &&
      this.selectedItems.filter(fileOrFolder => (fileOrFolder.name !== '..' && item.item !== fileOrFolder)).length === 0
    ) {
      this.selectedItems = []
    }

    // If top two, and filtered results in count -1, set to all.
    if (
      item.value &&
      this.selectedItems.length + 1 >= this.files.length &&
      this.selectedItems.filter(fileOrFolder => (fileOrFolder.name !== '..')).length + 1 === this.files.length
    ) {
      this.selectedItems = this.files
    }
  }

  // Determines if a row is currently in a draggable state or not.
  draggable (item: FileBrowserEntry | AppFileWithMeta) {
    return (
      item.name !== '..' &&
      this.files.length > 0 &&
      (
        this.selected.length === 0 ||
        this.selected.includes(item)
      )
    )
  }

  // Fake a drag image when the user drags a file or folder.
  handleDragStart (e: DragEvent) {
    if (e.dataTransfer) {
      this.ghost = document.createElement('div')
      this.ghost.classList.add('bulk-drag')
      this.ghost.classList.add((this.$vuetify.theme.dark) ? 'theme--dark' : 'theme--light')
      this.ghost.innerHTML = (this.selected.length > 0)
        ? `Move ${this.selected.length} items`
        : 'Move item'
      document.body.appendChild(this.ghost)
      e.dataTransfer.dropEffect = 'move'
      e.dataTransfer.setDragImage(this.ghost, 0, 0)
    }
  }

  // Table row is being dragged
  handleDrag (item: FileBrowserEntry | AppFileWithMeta) {
    if (this.dragState !== true) {
      this.dragItem = item
      this.$emit('update:dragState', true)
    }
  }

  // File was dropped on another table row.
  handleDrop (destination: FileBrowserEntry | AppFileWithMeta, e: { target: HTMLElement}) {
    this.handleDragLeave(e)
    if (
      destination.type === 'directory' &&
      this.dragItem &&
      this.dragItem !== destination &&
      !this.selected.includes(destination)
    ) {
      const source = (this.selected.length === 0)
        ? [this.dragItem]
        : this.selected.filter(item => (item.name !== '..'))
      this.$emit('move', source, destination)
    }
  }

  // Handles highlighting rows as drag over them
  handleDragOver (e: { target: HTMLElement}) {
    if (
      e.target.tagName === 'TD' &&
      e.target.parentElement?.classList.contains('is-directory')
    ) {
      const row = e.target.parentElement
      if (row) row.classList.add('active')
    }
  }

  // Handles un highlighting rows as we drag out of them.
  handleDragLeave (e: { target: HTMLElement}) {
    if (e.target.tagName === 'TD') {
      const row = e.target.parentElement
      if (row) row.classList.remove('active')
    }
  }

  // Drag ended
  handleDragEnd () {
    const e = this.ghost as HTMLDivElement
    document.body.removeChild(e)
    this.dragItem = null
    this.$emit('update:dragState', false)
  }
}
