
import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { Icons } from '@/globals'

@Component({})
export default class Configure extends Mixins(StateMixin) {
  get icons () {
    return Object.keys(Icons)
  }
}
