
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class PendingChangesDialog extends Vue {
  @Prop({ type: Boolean, required: true })
  public value!: boolean

  get saveConfigPendingItems () {
    const saveConfigPendingItems = this.$store.getters['printer/getSaveConfigPendingItems']

    const lines = Object.entries<Record<string, string>>(saveConfigPendingItems).map(section => {
      const [sectionName, sectionEntries] = section

      const entryValues = Object.entries(sectionEntries).map(entry => `${entry[0]}: ${entry[1]}`)

      return `[${sectionName}]\n${entryValues.join('\n')}`
    })

    return lines.join('\n\n')
  }

  handleSubmit () {
    this.$emit('input', false)
    this.$emit('save')
  }
}
