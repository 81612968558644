
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class AppInlineHelp extends Vue {
  @Prop({ type: String, required: false })
  public tooltip!: string

  @Prop({ type: String, default: 'primary' })
  public type!: string

  @Prop({ type: Boolean, default: false })
  public top!: boolean

  @Prop({ type: Boolean, default: false })
  public bottom!: boolean

  @Prop({ type: Boolean, default: false })
  public left!: boolean

  @Prop({ type: Boolean, default: false })
  public right!: boolean

  @Prop({ type: Boolean, default: false })
  public small!: boolean
}
