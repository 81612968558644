
import { Component, Vue, Prop, Ref } from 'vue-property-decorator'

@Component({})
export default class FileSystemMenu extends Vue {
  @Prop({ type: String, required: true })
  public root!: string

  // If the controls are disabled or not.
  @Prop({ type: Boolean, default: false })
  public disabled!: boolean

  @Ref('uploadFile')
  readonly uploadFile!: HTMLInputElement

  andPrint = false

  get readonly () {
    return this.$store.getters['files/getRootProperties'](this.root).readonly
  }

  get accepts () {
    return this.$store.getters['files/getRootProperties'](this.root).accepts.join(',')
  }

  get canCreateDirectory () {
    return this.$store.getters['files/getRootProperties'](this.root).canCreateDirectory
  }

  emulateClick (startPrint: boolean) {
    this.andPrint = startPrint
    this.uploadFile.multiple = !startPrint // Can't start print with multiple files
    this.uploadFile.click()
  }

  fileChanged (e: Event) {
    const target = e.target as HTMLInputElement
    const files = target.files
    const fileList = []

    if (target && files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        fileList.push(files[i])
      }

      this.$emit('upload', fileList, this.andPrint)
      target.value = ''
    }
  }
}
