
import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { Waits } from '@/globals'

@Component({})
export default class SpeedAndFlowAdjust extends Mixins(StateMixin) {
  waits = Waits

  get flow () {
    return Math.round(this.$store.state.printer.printer.gcode_move.extrude_factor * 100) || 100
  }

  handleSetFlow (val: number) {
    this.sendGcode('M221 S' + val, this.waits.onSetFlow)
  }

  get speed () {
    return Math.round(this.$store.state.printer.printer.gcode_move.speed_factor * 100) || 100
  }

  handleSetSpeed (val: number) {
    this.sendGcode('M220 S' + val, this.waits.onSetSpeed)
  }

  get isMobile () {
    return this.$vuetify.breakpoint.mobile
  }
}
