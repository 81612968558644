var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-subheader',{attrs:{"id":"general"}},[_vm._v(" "+_vm._s(_vm.$t('app.setting.title.general'))+" ")]),_c('v-card',{staticClass:"mb-4",attrs:{"elevation":5,"dense":""}},[_c('app-setting',{attrs:{"title":_vm.$t('app.setting.label.printer_name')}},[_c('v-text-field',{ref:"instanceName",attrs:{"filled":"","dense":"","single-line":"","hide-details":"auto","rules":_vm.instanceNameRules,"value":_vm.instanceName,"default-value":_vm.$globals.APP_NAME},on:{"change":_vm.setInstanceName}})],1),_c('v-divider'),_c('app-setting',{attrs:{"title":_vm.$t('app.setting.label.language')}},[_c('v-select',{attrs:{"filled":"","dense":"","single-line":"","hide-details":"auto","items":_vm.supportedLocales,"value":_vm.locale,"item-text":"name","item-value":"code"},on:{"change":_vm.setLocale}})],1),_c('v-divider'),_c('app-setting',{attrs:{"title":_vm.$t('app.setting.label.date_time_format')}},[_c('v-select',{attrs:{"filled":"","dense":"","hide-details":"auto","items":[
            { text: _vm.$filters.formatDateTime(_vm.current_time, 'MMM. DD, YYYY'), value: 'MMM. DD,' },
            { text: _vm.$filters.formatDateTime(_vm.current_time, 'DD MMM. YYYY'), value: 'DD MMM.' }
          ],"item-value":"value","item-text":"text"},model:{value:(_vm.dateformat),callback:function ($$v) {_vm.dateformat=$$v},expression:"dateformat"}}),_vm._v("   "),_c('v-select',{attrs:{"filled":"","dense":"","hide-details":"auto","items":[
            { text: _vm.$filters.formatDateTime(_vm.current_time, 'h:mm a'), value: 'hh:mm a' },
            { text: _vm.$filters.formatDateTime(_vm.current_time, 'HH:mm'), value: 'HH:mm' }
          ],"item-value":"value","item-text":"text"},model:{value:(_vm.timeformat),callback:function ($$v) {_vm.timeformat=$$v},expression:"timeformat"}})],1),_c('v-divider'),_c('app-setting',{attrs:{"title":_vm.$t('app.setting.label.confirm_on_estop')}},[_c('v-switch',{staticClass:"mb-5",attrs:{"hide-details":""},nativeOn:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.confirmOnEstop),callback:function ($$v) {_vm.confirmOnEstop=$$v},expression:"confirmOnEstop"}})],1),_c('v-divider'),_c('app-setting',{attrs:{"title":_vm.$t('app.setting.label.power_toggle_in_top_nav')}},[_c('v-select',{attrs:{"filled":"","dense":"","single-line":"","hide-details":"auto","items":[{ text: _vm.$tc('app.setting.label.none'), value: null }, ..._vm.powerDevicesList],"value":_vm.topNavPowerToggle,"item-value":"value","item-text":"text"},model:{value:(_vm.topNavPowerToggle),callback:function ($$v) {_vm.topNavPowerToggle=$$v},expression:"topNavPowerToggle"}})],1),_c('v-divider'),_c('app-setting',{attrs:{"title":_vm.$t('app.setting.label.confirm_on_power_device_change')}},[_c('v-switch',{staticClass:"mb-5",attrs:{"hide-details":""},nativeOn:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.confirmOnPowerDeviceChange),callback:function ($$v) {_vm.confirmOnPowerDeviceChange=$$v},expression:"confirmOnPowerDeviceChange"}})],1),_c('v-divider'),_c('app-setting',{attrs:{"title":_vm.$t('app.setting.label.confirm_on_save_config_and_restart')}},[_c('v-switch',{staticClass:"mb-5",attrs:{"hide-details":""},nativeOn:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.confirmOnSaveConfigAndRestart),callback:function ($$v) {_vm.confirmOnSaveConfigAndRestart=$$v},expression:"confirmOnSaveConfigAndRestart"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }