
import { Component, Prop, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { AppFile } from '@/store/files/types'

@Component({})
export default class GcodePreviewParserProgressDialog extends Mixins(StateMixin) {
  @Prop({ type: Boolean, default: false })
  public value!: boolean

  @Prop({ type: Number })
  public progress!: number

  @Prop({ type: Object })
  public file!: AppFile

  get percent () {
    return Math.floor((this.progress / this.file.size) * 100)
  }
}
