
import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { ConsoleFilter, ConsoleFilterType } from '@/store/console/types'
import ConsoleFilterDialog from './ConsoleFilterDialog.vue'

@Component({
  components: {
    ConsoleFilterDialog
  }
})
export default class ConsoleSettings extends Mixins(StateMixin) {
  dialogState: any = {
    open: false,
    rules: null,
    filter: null
  }

  get filters () {
    return this.$store.getters['console/getFilters']
  }

  handleEditFilterDialog (filter: ConsoleFilter) {
    const filterCopy = filter
      ? { ...filter }
      : {
          id: '',
          enabled: true,
          name: '',
          type: ConsoleFilterType.Contains,
          value: ''
        }

    this.dialogState = {
      open: true,
      rules: {
        required: (v: string) => !!v || this.$t('app.general.simple_form.error.required'),
        uniqueName: (v: string) => !this.filters.some((c: ConsoleFilter) => c.id !== this.dialogState.filter.id && c.name.toLowerCase() === v.toLowerCase()) || this.$t('app.general.simple_form.error.exists'),
        validExpression: (v: string) => {
          try {
            if (v) {
              // eslint-disable-next-line
              new RegExp(v)
            }
            return true
          } catch (e) { }
          return this.$t('app.general.simple_form.error.invalid_expression')
        }
      },
      filter: filterCopy
    }
  }

  handleRemoveFilter (filter: ConsoleFilter) {
    this.$store.dispatch('console/onRemoveFilter', filter)
  }

  handleSaveFilter (filter: ConsoleFilter) {
    this.$store.dispatch('console/onSaveFilter', filter)
  }
}
