
import McuConstantsDialog from './McuConstantsDialog.vue'
import { MCU } from '@/store/printer/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    McuConstantsDialog
  }
})
export default class PrinterStatsCard extends Vue {
  @Prop({ type: Object, required: true })
  public mcu!: MCU

  mcuConstantsDialogOpen = false

  showMcuConstantsDialog () {
    this.mcuConstantsDialogOpen = true
  }
}
